import * as _ from 'lodash';
import * as React from 'react';
import { Accordion, Checkbox, Dropdown, Form, Icon, Label, Menu, MenuItem, Segment, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import * as URL from 'url';

interface Link {
  url: string,
  filteredUrl?: string,
  parsed: URL.UrlWithParsedQuery,
  title?: string,
  text?: string,
  protocol: string,
  hostname: string,
  source: string,
}

export interface LinkerProps {
  source?: string
}

export interface LinkerState extends LinkerProps {
  links?: Link[]
  groupedLinks?: { link: string, links: Link[] }[],
  filter: Filter
  filterActive: { [key: string]: boolean }
}

function emptyFilter(): Filter {
  return {
    disallowedProtocol: [],
    disallowedHostname: [],
    disallowedSource: [],
    filteredUrlRegExp: [],
  }
}

interface Filter {
  disallowedProtocol: string[]
  disallowedHostname: string[]
  disallowedSource: string[]
  filteredUrlRegExp: string[]
}

export default class Linker extends React.Component<LinkerProps, LinkerState> {
  state: LinkerState = {
    source: "",
    links: [],
    groupedLinks: [],
    filter: emptyFilter(),
    filterActive: {}
  }

  resultContainerNode: HTMLDivElement

  presets = {
    "Magnet": {
      icon: ((t: SemanticICONS) => t)("magnet"),
      color: ((t: SemanticCOLORS) => t)("brown"),
      apply: () => {
        this.updateFilter({
          ...emptyFilter(),
          disallowedSource: _.pull(this.allallSources, "a[href]"),
          disallowedProtocol: _.pull(this.allallProtocols, "magnet:"),
          disallowedHostname: _.pull(this.allallHostnames, "")
        });
      }
    },
    "eMule": {
      icon: ((t: SemanticICONS) => t)("sticker mule"),
      color: ((t: SemanticCOLORS) => t)("yellow"),
      apply: () => {
        this.updateFilter({
          ...emptyFilter(),
          disallowedSource: _.pull(this.allallSources, "a[href]"),
          disallowedProtocol: _.pull(this.allallProtocols, "ed2k:"),
          disallowedHostname: _.pull(this.allallHostnames, "")
        });
      }
    },
    "百度盘": {
      icon: ((t: SemanticICONS) => t)("disk"),
      color: ((t: SemanticCOLORS) => t)("blue"),
      apply: () => {
        this.updateFilter({
          ...emptyFilter(),
          disallowedSource: _.pull(this.allallSources, "a[href]"),
          disallowedProtocol: _.pull(this.allallProtocols, "http:", "https:"),
          disallowedHostname: _.pull(this.allallHostnames, "pan.baidu.com", "yun.baidu.com")
        });
      }
    },
    "YouTube": {
      icon: ((t: SemanticICONS) => t)("youtube"),
      color: ((t: SemanticCOLORS) => t)("red"),
      apply: () => {
        this.updateFilter({
          ...emptyFilter(),
          disallowedSource: _.pull(this.allallSources, "a[href]"),
          disallowedProtocol: _.pull(this.allallProtocols, "http:", "https:"),
          disallowedHostname: _.pull(this.allallHostnames, "www.youtube.com", "youtube.com", "youtu.be"),
          filteredUrlRegExp: ["watch\\?", 'youtu\\.be/']
        });
      }
    },
    "哔哩哔哩": {
      icon: ((t: SemanticICONS) => t)("tv"),
      color: ((t: SemanticCOLORS) => t)("pink"),
      apply: () => {
        this.updateFilter({
          ...emptyFilter(),
          disallowedSource: _.pull(this.allallSources, "a[href]"),
          disallowedProtocol: _.pull(this.allallProtocols, "http:", "https:"),
          disallowedHostname: _.pull(this.allallHostnames, "www.bilibili.com", "bangumi.bilibili.com"),
          filteredUrlRegExp: ["\\/av\\d+", "\\/ep\\d+"]
        });
      }
    },
  }

  get filteredLinks() { return _.flatten(this.state.groupedLinks.map((i) => i.links)) }
  get allallProtocols() { return _.uniq(_.map(this.state.links, "protocol")).sort() }
  get allallHostnames() { return _.uniq(_.map(this.state.links, "hostname")).sort() }
  get allallSources() { return _.uniq(_.map(this.state.links, "source")).sort() }
  get allProtocols() { return _.uniq([...this.state.filter.disallowedProtocol, ..._.map(this.filteredLinks, "protocol")]).sort() }
  get allHostnames() { return _.uniq([...this.state.filter.disallowedHostname, ..._.map(this.filteredLinks, "hostname")]).sort() }
  get allSources() { return _.uniq([...this.state.filter.disallowedSource, ..._.map(this.filteredLinks, "source")]).sort() }

  renderFilterItem(o: {
    key: string,
    title: string,
    index: number,
    content: React.ReactNode
  }) {
    return <Menu.Item>
      <Accordion.Title
        active={this.state.filterActive[o.key]}
        content={o.title}
        index={o.index}
        onClick={() => this.setState({ filterActive: { ...this.state.filterActive, [o.key]: !this.state.filterActive[o.key] } })}
      />
      <Accordion.Content active={this.state.filterActive[o.key]} content={o.content} />
    </Menu.Item>
  }

  render() {
    return (
      <>
        <div className="nav-filter">
          <Menu inverted className="no-border-radius">
            <MenuItem as="a" active>Filter</MenuItem>
            <MenuItem position="right" style={{ margin: "0", padding: "0" }}></MenuItem>
            <Dropdown text="Presets" item={true}>
              <Dropdown.Menu>
                {
                  Object.keys(this.presets).map((key) => {
                    return <Dropdown.Item key={key} onClick={() => this.presets[key].apply()}>
                      <Icon name={this.presets[key].icon || "gift"} color={this.presets[key].color || "black"}></Icon> {key}
                    </Dropdown.Item>
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
            <MenuItem onClick={() => this.updateFilter(emptyFilter())}>Reset</MenuItem>
          </Menu>
        </div>
        <div className="nav-result">
          <Menu inverted className="no-border-radius">
            <MenuItem as="a" active>Result</MenuItem>
            <MenuItem position="right" style={{ margin: "0", padding: "0" }}></MenuItem>
            <Menu.Item>{this.state.groupedLinks.length.toLocaleString()} items</Menu.Item>
            <Menu.Item as="a" onClick={() => {
              if (!this.resultContainerNode) return;
              if ((document as any).selection) {
                const range = (document.body as any).createTextRange();
                range.moveToElementText(this.resultContainerNode);
                range.select().createTextRange();
                document.execCommand("copy");
              } else if (window.getSelection) {
                const range = document.createRange();
                range.selectNode(this.resultContainerNode);
                window.getSelection().empty();
                window.getSelection().addRange(range);
                document.execCommand("copy");
              }
            }}>Copy</Menu.Item>
          </Menu>
        </div>
        <div className="main-filter">
          <Accordion as={Menu} vertical inverted className="main-filter-accordion">
            {this.renderFilterItem({
              index: 0,
              title: `URL RegExps (${this.state.filter.filteredUrlRegExp.length})`,
              key: "urlRegExps",
              content: <>
                <Form>
                  <Form.Group inline={false} grouped={true}>
                    <textarea
                      value={this.state.filter.filteredUrlRegExp.join("\n")}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const keywords = _.compact((e.target.value || "").split("\n").map((i) => i.trim()))
                        this.updateFilter({ filteredUrlRegExp: keywords })
                      }}
                    ></textarea>
                  </Form.Group>
                </Form>
              </>
            })}

            {this.renderFilterItem({
              index: 1,
              title: `Source (${_.sumBy(this.allSources, (source) => this.state.filter.disallowedSource.indexOf(source) < 0 ? 1 : 0)}/${this.allSources.length})`,
              key: "source",
              content: <>
                <Form>
                  <Form.Group inline={false} grouped={true}>
                    <Label compact onClick={() => {
                      this.updateFilter({ disallowedSource: [] });
                    }}>All</Label>
                    <Label compact onClick={() => {
                      this.updateFilter({ disallowedSource: this.allSources });
                    }}>None</Label>
                    {this.allSources.map((source) => {
                      return <Form.Field
                        key={source}
                        control={Checkbox}
                        label={String(source || "(none)")}
                        checked={this.state.filter.disallowedSource.indexOf(source) < 0}
                        onChange={(e, { checked }) => {
                          if (checked) {
                            this.updateFilter({ disallowedSource: _.pullAll([...this.state.filter.disallowedSource], [source]) });
                          } else {
                            this.updateFilter({ disallowedSource: _.uniq([...this.state.filter.disallowedSource, source]) });
                          }
                        }}
                      />
                    })}
                  </Form.Group>
                </Form>
              </>
            })}

            {this.renderFilterItem({
              index: 2,
              title: `Protocol (${_.sumBy(this.allProtocols, (protocol) => this.state.filter.disallowedProtocol.indexOf(protocol) < 0 ? 1 : 0)}/${this.allProtocols.length})`,
              key: "protocol",
              content: <>
                <Form>
                  <Form.Group inline={false} grouped={true}>
                    <Label compact onClick={() => {
                      this.updateFilter({ disallowedProtocol: [] });
                    }}>All</Label>
                    <Label compact onClick={() => {
                      this.updateFilter({ disallowedProtocol: this.allProtocols });
                    }}>None</Label>
                    {this.allProtocols.map((protocol) => {
                      return <Form.Field
                        key={protocol}
                        control={Checkbox}
                        label={String(protocol || "(none)")}
                        checked={this.state.filter.disallowedProtocol.indexOf(protocol) < 0}
                        onChange={(e, { checked }) => {
                          if (checked) {
                            this.updateFilter({ disallowedProtocol: _.pullAll([...this.state.filter.disallowedProtocol], [protocol]) });
                          } else {
                            this.updateFilter({ disallowedProtocol: _.uniq([...this.state.filter.disallowedProtocol, protocol]) });
                          }
                        }}
                      />
                    })}
                  </Form.Group>
                </Form>
              </>
            })}

            {this.renderFilterItem({
              index: 3,
              title: `Hostname (${_.sumBy(this.allHostnames, (hostname) => this.state.filter.disallowedHostname.indexOf(hostname) < 0 ? 1 : 0)}/${this.allHostnames.length})`,
              key: "hostname",
              content: <>
                <Form>
                  <Form.Group inline={false} grouped={true}>
                    <Label onClick={() => {
                      this.updateFilter({ disallowedHostname: [] });
                    }}>All</Label>
                    <Label onClick={() => {
                      this.updateFilter({ disallowedHostname: this.allHostnames });
                    }}>None</Label>
                    {this.allHostnames.map((hostname) => {
                      return <Form.Field
                        key={hostname}
                        control={Checkbox}
                        label={String(hostname || "(none)")}
                        checked={this.state.filter.disallowedHostname.indexOf(hostname) < 0}
                        onChange={(e, { checked }) => {
                          if (checked) {
                            this.updateFilter({ disallowedHostname: _.pullAll([...this.state.filter.disallowedHostname], [hostname]) });
                          } else {
                            this.updateFilter({ disallowedHostname: _.uniq([...this.state.filter.disallowedHostname, hostname]) });
                          }
                        }}
                      />
                    })}
                  </Form.Group>
                </Form>
              </>
            })}
          </Accordion>
        </div>
        <div className="main-result">
          <div style={{ padding: "10px" }} ref={(node) => this.resultContainerNode = node}>
            {this.state.groupedLinks.map((i, index) => {
              return <pre key={index}>{i.link}</pre>
            })}
          </div>
        </div>
      </>
    );
  }

  static rescue<T>(val: () => T, fallback: T): T {
    try {
      return val();
    } catch (e) {
      return fallback;
    }
  }

  static parseLinks(source: string): Link[] {
    const div = document.createElement('div');
    div.innerHTML = source.trim();

    const result: Link[] = [];
    const pushLink = (l: Partial<Link> & { url: string, source: string }) => {
      const parsed = this.rescue(() => URL.parse(l.url, true), null);
      result.push({
        filteredUrl: this.filterUrl(l.url, parsed),
        parsed: parsed,
        protocol: (parsed && parsed.protocol) || "",
        hostname: (parsed && ['https:', 'http:', 'ftp:', 'sftp:', 'git:'].indexOf(parsed.protocol) >= 0 && parsed.hostname) || "",
        ...l,
      })
    }

    for (const element of Array(...div.querySelectorAll("a[href]") as any as HTMLAnchorElement[])) {
      pushLink({
        url: element.getAttribute("href"),
        source: "a[href]",
        title: String(element.getAttribute("title") || "").trim(),
        text: String(element.innerText || "").trim(),
      })
    }

    for (const element of Array(...div.querySelectorAll("img[src]") as any as HTMLImageElement[])) {
      pushLink({
        url: element.getAttribute("src"),
        source: "img[src]",
        title: String(element.getAttribute("title") || "").trim(),
        text: String(element.getAttribute("alt") || "").trim(),
      })
    }

    return result;
  }

  static filterUrl(url: string, parsed?: URL.UrlWithParsedQuery) {
    if (!parsed) parsed = this.rescue(() => URL.parse(url, true), null);
    if (!parsed) return;
    if (parsed.protocol == "http:" || parsed.protocol == "https:") {
      if (parsed.hostname == "www.youtube.com") {
        if (parsed.pathname == "/watch" && parsed.query && parsed.query.v) {
          return URL.format({
            ...parsed,
            search: undefined,
            query: {
              "v": parsed.query.v
            }
          })
        }
      }
      if (parsed.hostname == "www.bilibili.com") {
        if (parsed.pathname.startsWith("/video/av") || parsed.pathname.startsWith("/bangumi/play/ss")) {
          return URL.format({
            ...parsed,
            slashes: false,
            search: undefined,
            query: _.pickBy(parsed.query, (i, x) => {
              return ["spm_id_from", "from", "seid"].indexOf(x) == -1
            })
          })
        }
      }
    }
    return url
  }

  static groupLinks(links: Link[], filter: Filter) {
    const keywordRegExps = filter.filteredUrlRegExp.map((i) => {
      try { return new RegExp(i) } catch (e) { return undefined }
    }).filter((i) => i)
    const fLinks = links.filter((link) => {
      if (filter.disallowedSource.indexOf(link.source) >= 0) return false;
      if (filter.disallowedProtocol.indexOf(link.protocol) >= 0) return false;
      if (filter.disallowedHostname.indexOf(link.hostname) >= 0) return false;
      if (keywordRegExps.length > 0) {
        if (!keywordRegExps.some((i) => !!(link.filteredUrl || link.url).match(i))) {
          return false;
        }
      }
      return true;
    })
    return _.values(_.groupBy(fLinks, (link) => link.filteredUrl || link.url)).map((group) => {
      return {
        link: group[0].filteredUrl || group[0].url,
        links: group
      }
    })
  }

  updateFilter(f: Partial<Filter>) {
    const x = Object.assign({}, this.state.filter, f)
    this.setState({
      filter: x,
      groupedLinks: Linker.groupLinks(this.state.links, x)
    })
  }

  static getDerivedStateFromProps(props: LinkerProps, state: LinkerState) {
    if (props.source !== state.source) {
      const links = Linker.parseLinks(props.source);
      const filter = state.filter || emptyFilter()
      return {
        source: props.source,
        links: links,
        groupedLinks: Linker.groupLinks(links, state.filter),
        filter: filter
      };
    }

    return null;
  }
}
