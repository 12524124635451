import * as React from 'react';
import * as sanitizeHtml from 'sanitize-html';
import { Menu } from 'semantic-ui-react';
import './../assets/scss/App.scss';
import ContentEditable from './ContentEditable';
import HTMLCollector from './HTMLCollector';
import HTMLPasteBox from './HTMLPasteBox';
import Linker from './Linker';

export interface AppProps {
}

export enum InputModes {
  HTMLCollector,
  TextArea,
  ContentEditable
}
export interface AppState {
  source?: string
  viewMode?: InputModes
}

export default class App extends React.Component<AppProps, AppState> {
  state = {
    source: "",
    viewMode: InputModes.HTMLCollector
  }

  handleTextAreaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ source: String(e.target.value) })
  }

  handleContentEditableChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ source: String(e.target.value) })
  }

  handlePasteHTML(html: string) {
    this.setState({ source: this.state.source + html });
  }

  sanitizeConf = {
    allowedTags: ["a", "img", "link"],
    allowedAttributes: {
      "*": [
        "style", "*"
      ]
    },
    nonTextTags: ['style', 'script', 'textarea'],
    allowedClasses: {},
    allowedStyles: { '*': {} },
    allowedSchemes: { indexOf() { return 233; } },
    exclusiveFilter(frame) {
      return frame.tag === 'iframe' || frame.tag === 'style' || frame.tag === 'script';
    }
  };

  sanitize() {
    this.setState({ source: sanitizeHtml(this.state.source, this.sanitizeConf) })
  }

  renderTextArea() {
    return (
      <textarea
        className="main-input-textarea"
        onChange={this.handleTextAreaChange.bind(this)}
        value={this.state.source}
        style={{
          margin: 0, padding: "10px",
          width: "100%",
          height: "100%",
          border: "none",
          resize: "none",
          boxSizing: "border-box",
        }}
      />
    )
  }

  renderContentEditable() {
    return (
      <ContentEditable
        className="main-input-content-editable"
        html={this.state.source}
        disabled={false}
        onChange={this.handleContentEditableChange.bind(this)}
        onBlur={this.sanitize.bind(this)}
        tagName='div'
        style={{
          margin: 0, padding: "10px",
          overflow: "auto",
          width: "100%",
          height: "100%",
          border: "none",
          boxSizing: "border-box",
        }}
      />
    )
  }

  renderHTMLCollector() {
    return (
      <HTMLCollector
        onPasteHTML={this.handlePasteHTML.bind(this)}
        sanitizer={(h) => sanitizeHtml(h, this.sanitizeConf) }
        style={{
          margin: 0,
          overflow: "auto",
          width: "100%",
          height: "100%",
          border: "none",
          boxSizing: "border-box",
        }}
      />
    )
  }

  render() {
    return (
      <div className="app">
        <div className="nav-logo">
          <Menu inverted className="no-border-radius">
            <Menu.Item active header as="a" href="https://linker.orz.icu">Linker</Menu.Item>
            <Menu.Item position="right" header as="a" href="https://orz.icu">🚑 ICU</Menu.Item>
          </Menu>
        </div>
        <div className="dummy-input"></div>
        <div className="nav-input">
          <Menu inverted className="no-border-radius">
            <Menu.Item active={this.state.viewMode == InputModes.HTMLCollector} onClick={() => this.setState({ viewMode: InputModes.HTMLCollector })}>Collector</Menu.Item>
            <Menu.Item active={this.state.viewMode == InputModes.ContentEditable} onClick={() => this.setState({ viewMode: InputModes.ContentEditable })}>WYSIWYG</Menu.Item>
            <Menu.Item active={this.state.viewMode == InputModes.TextArea} onClick={() => this.setState({ viewMode: InputModes.TextArea })}>Source</Menu.Item>
            <Menu.Item position="right" style={{ margin: "0", padding: "0" }}></Menu.Item>

            <Menu.Item>{this.state.source.length.toLocaleString()} bytes</Menu.Item>
            {this.state.viewMode !== InputModes.HTMLCollector && <>
              <HTMLPasteBox
                onPasteHTML={this.handlePasteHTML.bind(this)}
                sanitizer={(h) => sanitizeHtml(h, this.sanitizeConf)}
              ></HTMLPasteBox>
            </>}

            <Menu.Item as="a" onClick={() => this.setState({ source: "" })}>Clean</Menu.Item>
          </Menu>
        </div>
        <div className="main-input">
          {this.state.viewMode == InputModes.HTMLCollector ? this.renderHTMLCollector() : null}
          {this.state.viewMode == InputModes.ContentEditable ? this.renderContentEditable() : null}
          {this.state.viewMode == InputModes.TextArea ? this.renderTextArea() : null }
        </div>
        <Linker source={this.state.source}></Linker>
      </div>
    );
  }
}
