import * as PropTypes from 'prop-types';
import * as React from 'react';

function htmlspecialchars(str) {
  if (str == null) return '';

  return String(str).
    replace(/&/g, '&amp;').
    replace(/</g, '&lt;').
    replace(/>/g, '&gt;').
    replace(/"/g, '&quot;').
    replace(/'/g, '&#039;');
};

export default class HTMLPasteBox extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  htmlElementNode: HTMLDivElement
  onPasteTriggered: boolean = false

  resetElement(e?: any) {
    this.htmlElementNode.innerHTML = "";
    if (e && e.preventDefault) {
      event.preventDefault();
    }
  }
  resetElementBinded: this['resetElement']

  onPaste(event: React.ClipboardEvent<HTMLDivElement>) {
    event.preventDefault();
    const types = event.clipboardData.types;
    if (types.indexOf("text/html") >= 0) {
      const html = event.clipboardData.getData("text/html");
      return this.appendHtml(html);
    } else if (types.indexOf("text/plain") >= 0) {
      const text = event.clipboardData.getData("text/plain");
      const html = `<pre>${htmlspecialchars(text)}</pre>`;
      return this.appendHtml(html);
    }
  }
  onPasteBinded: this['onPaste']

  render() {
    if (!this.resetElementBinded) this.resetElementBinded = this.resetElement.bind(this);
    if (!this.onPasteBinded) this.onPasteBinded = this.onPaste.bind(this);

    return <div
      className={this.props.className || "html-paste-box"}
      contentEditable={true}
      ref={(node) => this.htmlElementNode = node}
      onChange={this.resetElementBinded}
      onDrop={this.resetElementBinded}
      onInput={this.resetElementBinded}
      onDrag={this.resetElementBinded}
      onDragStart={this.resetElementBinded}
      onPaste={this.onPasteBinded}
    ></div>
  }

  appendHtml(html: string) {
    if (!html) return;

    html = this.props.sanitizer(html);
    if (!html) return;

    this.props.onPasteHTML(html);
  }

  static propTypes = {
    onPasteHTML: PropTypes.func,
    sanitizer: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
  }
}

export interface Props {
  onPasteHTML?: Function,
  className?: string,
  style?: React.CSSProperties
  sanitizer: (html: string) => string
}