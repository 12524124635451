import * as PropTypes from 'prop-types';
import * as React from 'react';
import HTMLPasteBox from './HTMLPasteBox';

export default class HTMLCollector extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return <div className="html-collector" style={{
      textAlign: "center",
      padding: "10px",
      ...this.props.style
    }} >
      <HTMLPasteBox
        className="html-collector-paste-box"
        onPasteHTML={this.props.onPasteHTML}
        sanitizer={this.props.sanitizer}
      ></HTMLPasteBox>
    </div>
  }

  static propTypes = {
    onPasteHTML: PropTypes.func,
    sanitizer: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
  }
}

export interface Props {
  onPasteHTML?: Function,
  className?: string,
  style?: React.CSSProperties
  sanitizer: (html: string) => string
}